import React, { ReactElement } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Order } from '../../../../../domain';
import { Color } from '../../../../constants';
import { AppText } from '../../../AppText';
import { If } from '../../../If';

type Props = { order: Order };

export const AllergensView = ({ order }: Props): ReactElement =>
  <If condition={(order.items?.filter(({ allergens }) => !!allergens)?.length ?? 0) > 0}>
    <View style={styles.view}>
      <Image source={require('../../../../assets/icon/allergies.png')} resizeMode="contain" style={styles.icon}/>
      <AppText style={styles.comment}>
        アレルギー対応リクエスト：{order.items?.filter(({ allergens }) => allergens)?.length}件
      </AppText>
    </View>
  </If>;

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    marginTop: 8,
    padding: 6,
    backgroundColor: 'rgba(247, 78, 47, 0.05)',
    borderRadius: 4,
    alignItems: 'center',
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: 2,
    marginRight: 6,
  },
  comment: {
    fontSize: 14,
    fontWeight: '500',
    color: Color.red,
    paddingRight: 16,
  },
});
