import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import {
  BusinessDayTime,
  DAYS,
  findNextClosingDayTime,
  findNextOpeningDayTime,
  isDuringBusinessDayTime,
  OrderPlatformSuspension,
  Platform,
  SuspensionStatus,
} from '../../../../domain';
import { dateToDayjs, endOfToday, startOfToday } from '../../../../utils';
import { Color } from '../../../constants';
import { PlatformTypeDecorator } from '../../../decorators';
import { useAppContext } from '../../../hooks';
import { Formatter } from '../../../utils';
import { AppText } from '../../AppText';
import { Clickable } from '../../Clickable';

interface OrderPlatformSuspensionListItemProps {
  platform: Platform
  orderPlatformSuspension: OrderPlatformSuspension | null
  businessDayTimes: BusinessDayTime[] | undefined

  onPressCreateOrderPlatformSuspension: (platform: Platform) => void;
  onPressDeleteOrderPlatformSuspension: (platform: Platform) => void;
}

export const OrderPlatformSuspensionListItem = (props: OrderPlatformSuspensionListItemProps): React.ReactElement => {
  const {
    platform,
    businessDayTimes,
    orderPlatformSuspension,
    onPressCreateOrderPlatformSuspension,
    onPressDeleteOrderPlatformSuspension
  } = props;
  const { integrations } = useAppContext();

  const businessDayTimesByPlatform = businessDayTimes
    ?.filter(businessDayTime => businessDayTime.brandId === platform.brandId)
    ?.filter(businessDayTime => businessDayTime.platformType === platform.id)
    ?? [];

  const isOpen = integrations
    ?.find(integration => integration.platformType === platform.type)
    ?.features.businessDayTimes.fetch.supported
    ? isDuringBusinessDayTime(businessDayTimesByPlatform)
    : null;

  const currentSuspensionStatus: SuspensionStatus = orderPlatformSuspension?.invalidatedTime === null
    ? 'suspended'
    : 'notSuspended';

  const nextOpeningTime = isOpen === false
    ? findNextOpeningDayTime(businessDayTimesByPlatform)
    : null;

  const nextClosingDayTime = isOpen === true && currentSuspensionStatus === 'notSuspended'
    ? findNextClosingDayTime(businessDayTimesByPlatform)
    : null;

  const primaryText = isOpen === false
    ? '営業時間外'
    : currentSuspensionStatus === 'notSuspended'
      ? '受付中'
      : '停止中';

  const subText = () => {
    const nextOpeningDayTimeString = () => {
      if (!nextOpeningTime) return null;

      const day = DAYS[nextOpeningTime.day()];
      return `${day ? Formatter.dayJP(day) : ''}${nextOpeningTime.format('HH:mm')}に再開`;
    };

    const nextClosingDayTimeString = () => {
      if (!nextClosingDayTime) return '営業終了時間に停止';

      return `本日${nextClosingDayTime.format('HH:mm')}に停止`;
    };

    const suspendedNextOpeningDayTimeString = () => {
      const suspensionWillBeDeletedToday = orderPlatformSuspension
        && endOfToday().isAfter(dateToDayjs(orderPlatformSuspension.suspendUntil.toDate()))
        && startOfToday().isBefore(dateToDayjs(orderPlatformSuspension.suspendUntil.toDate()));

      const day = DAYS[nextOpeningTime?.day() ?? 0];
      const dayString = nextOpeningTime && day
        ? Formatter.dayJP(day)
        : suspensionWillBeDeletedToday
          ? '本日'
          : '';

      const timeString = nextOpeningTime
        ? nextOpeningTime?.format('HH:mm') || '--:--'
        : suspensionWillBeDeletedToday
          ? Formatter.time(orderPlatformSuspension.suspendUntil.toDate())
          : '次の営業開始時間';

      return `${dayString}${timeString}に再開`;
    };

    if (isOpen === false)
      return nextOpeningDayTimeString();
    if (currentSuspensionStatus === 'notSuspended')
      return nextClosingDayTimeString();
    if (currentSuspensionStatus === 'suspended')
      return suspendedNextOpeningDayTimeString();
  };

  const propForEllipse = isOpen === false
    ? styles.grayEllipse
    : currentSuspensionStatus === 'notSuspended'
      ? styles.blueEllipse
      : styles.redEllipase;

  return <View style={styles.view}>
    <View style={styles.platformGroup}>
      <Image
        source={new PlatformTypeDecorator(platform.type, integrations).icon()}
        style={styles.platformIcon}
        resizeMode="contain" />
      <AppText style={styles.platformText} numberOfLines={1}>
        {new PlatformTypeDecorator(platform.type, integrations).name()}
      </AppText>
    </View>
    <View style={styles.divider}/>
    <View style={styles.state}>
      <View style={styles.stateFrame}>
        <View style={propForEllipse} />
        <AppText style={styles.statePrimaryText}>{primaryText}</AppText>
      </View>
      <AppText style={styles.stateSubText}>{subText()}</AppText>
    </View>
    <View style={styles.divider}/>
    <View style={styles.actionFrame}>
      <ActionButton {...{
        orderPlatformSuspension,
        suspensionStatus: currentSuspensionStatus,
        platform,
        onPressCreateOrderPlatformSuspension,
        onPressDeleteOrderPlatformSuspension
      }} />
    </View>
  </View>;
};

interface ActionButtonProps {
  platform: Platform
  suspensionStatus: SuspensionStatus

  onPressCreateOrderPlatformSuspension: (platform: Platform) => void;
  onPressDeleteOrderPlatformSuspension: (platform: Platform) => void;
}

const ActionButton = (props: ActionButtonProps): React.ReactElement => {
  const { platform, suspensionStatus } = props;
  const { onPressCreateOrderPlatformSuspension, onPressDeleteOrderPlatformSuspension } = props;

  const title = ({
    notSuspended: '停止する',
    suspended: '再開する',
  })[suspensionStatus];

  const icon = ({
    notSuspended: require('../../../assets/icon/pause.png'),
    suspended: require('../../../assets/icon/play.png')
  })[suspensionStatus];

  const onPress = useCallback(() => {
    const f = {
      notSuspended: onPressCreateOrderPlatformSuspension,
      suspended: onPressDeleteOrderPlatformSuspension
    }[suspensionStatus];
    f(platform);
  }, [onPressCreateOrderPlatformSuspension, onPressDeleteOrderPlatformSuspension, platform, suspensionStatus]);

  return <Clickable name="button_tap" id={title} onPress={onPress}>
    <View style={styles.actionButtonView}>
      {icon ? <Image style={styles.actionButtonIcon} source={icon} resizeMode="contain" /> : null}
      <AppText style={styles.actionButtonText}>{title}</AppText>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Color.white,
    borderWidth: 1,
    borderColor: Color.gray10,
    borderRadius: 12,
    flexDirection: 'row',
    marginBottom: 16,
    height: 104
  },
  divider: {
    borderColor: Color.gray5,
    borderWidth: 0.5,
    height: 40,
  },
  platformGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    paddingLeft: 32,
    paddingRight: 38,
    width: 200,
  },
  platformIcon: {
    width: 40,
    height: 40,
    borderRadius: 20
  },
  platformText: {
    fontWeight: '500',
    fontSize: 16,
    marginLeft: 16
  },
  state: {
    paddingLeft: 32,
    flexDirection: 'column',
    height: 48,
    minWidth: 200,
  },
  stateFrame: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  statePrimaryText: {
    color: Color.gray100,
    fontSize: 16,
    marginLeft: 6
  },
  stateSubText: {
    fontSize: 12,
    color: Color.gray50,
    marginTop: 6
  },
  blueEllipse: {
    backgroundColor: Color.blue_light,
    borderRadius: 6,
    width: 12,
    height: 12
  },
  redEllipase: {
    backgroundColor: Color.red,
    borderRadius: 6,
    width: 12,
    height: 12
  },
  grayEllipse: {
    backgroundColor: Color.gray20,
    borderRadius: 6,
    width: 12,
    height: 12
  },
  actionFrame: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 32,
    minWidth: 272,
    height: 40
  },
  actionText: {
    textAlign: 'right',
    fontSize: 12,
    color: Color.gray50
  },
  actionButtonView: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    borderColor: Color.gray10,
    borderWidth: 1,
    borderRadius: 4,
    marginLeft: 24,
    height: 36,
    width: 112,
  },
  actionButtonIcon: {
    width: 20,
    height: 20
  },
  actionButtonText: {
    color: Color.gray100,
    fontSize: 14,
    marginTop: -1,
    marginLeft: 10
  }
});
